import { extractPageDataForSEO } from '~/helpers/dataHelpers'
import { graphql } from 'gatsby'
import PageWrapper from '~/components/PageWrapper'
import React from 'react'
import SEO from '~/components/SEO'

export default function Pricing({ data, location }) {
  return (
    <PageWrapper
      path={location.pathname}
      modules={data.contentfulPage?.moduleContents}
    >
      <SEO
        {...extractPageDataForSEO(data.contentfulPage)}
        pathname={location.pathname}
      />
    </PageWrapper>
  )
}

export const homePageQuery = graphql`
  query PricingPageQuery {
    contentfulPage(name: { eq: "Pricing" }) {
      ...basicPageInfo
    }
  }
`
